import styled from "styled-components";
import SvgSelector from "../../Shared/SvgSelector";
import { BREAKPOINTS } from "../../../styles/variables";

const MediaCard = ({ title, link, image }) => {
  return (
    <CardWr>
      {/*<CardTitle>{title}</CardTitle>*/}
      <CardSource
        href={link.href}
        target="_blank"
      >
        <SvgSelector svg="mediaSource" />
        {link.name}
      </CardSource>

      <CardImg
        src={image}
        alt={link.name}
      />
    </CardWr>
  );
};

const CardWr = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h5`
  margin-top: 24px;
  margin-bottom: 53px;
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  text-transform: uppercase;

  @media ${BREAKPOINTS.laptop} {
    margin-top: 1.71vw;
    margin-bottom: 3.79vw;
    font-size: 1.43vw;
    line-height: 1.71vw;
  }

  @media ${BREAKPOINTS.mobile} {
    margin-top: 3.75vw;
    margin-bottom: 5vw;
    font-size: 6.25vw;
    line-height: 1.71vws;
  }
`;

const CardSource = styled.a`
  margin-bottom: 25px;
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 41px;
    height: 41px;
  }

  @media ${BREAKPOINTS.laptop} {
    margin-bottom: 1.79vw;
    font-size: 1.43vw;
    gap: 0.86vw;
    svg {
      width: 2.93vw;
      height: 2.93vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    margin-bottom: 5vw;
    font-size: 5vw;
    gap: 3.75vw;
    svg {
      width: 11.25vw;
      height: 11.25vw;
    }
  }
`;

const CardImg = styled.img`
  width: 387px;
  height: 282px;
  border-radius: 24px;
  object-fit: cover;
  @media ${BREAKPOINTS.laptop} {
    width: 27.64vw;
    height: 20.14vw;
    border-radius: 1.71vw;
  }

  @media ${BREAKPOINTS.mobile} {
    width: 87.5vw;
    height: 90.94vw;
    border-radius: 7.5vw;
  }
`;

export default MediaCard;
