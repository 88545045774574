import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SvgSelector from "../../../Shared/SvgSelector";
import ResultsCard from "./ResultsCard";
import { ContentWr } from "../../../../styles/textTags";
import { BREAKPOINTS, COLORS } from "../../../../styles/variables";
import ResultsSlider from "./ResultsSlider";

const ResultsDropdown = ({ title, slug, content }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpenDropdown]);

  return (
    <DropdownItem>
      <DropdownContent>
        <DropdownTitle
          $isopendropdown={isOpenDropdown}
          onClick={() => setIsOpenDropdown((state) => !state)}
        >
          {title}
          <SvgSelector svg="dropdownArrow" />
        </DropdownTitle>
        <MobileDropdownTitle
          $isopendropdown={isOpenDropdown}
          onClick={() => setIsOpenDropdown((state) => !state)}
        >
          {title}
          <MobileAddressBlock $isopendropdown={isOpenDropdown}>
            <SvgSelector svg="mark" />
            {content.address}
          </MobileAddressBlock>
          <SvgSelector svg="dropdownArrow" />
        </MobileDropdownTitle>
      </DropdownContent>
      <DropdownInside
        ref={contentRef}
        $isopendropdown={isOpenDropdown}
        $contentheight={contentHeight}
      >
        <div>
          <DropdownContent>
            <AddressBlock>
              <SvgSelector svg="mark" />
              {content.address}
            </AddressBlock>

            <CardsBlock>
              {content.cards.map((card, index) => {
                return (
                  <ResultsCard
                    key={index}
                    title={card.title}
                    image={require(`../../../../assets/results/${slug}/${index === 0 ? 'location' : 'artist'}.png`)}
                    paragraph={card.paragraph}
                  />
                );
              })}
            </CardsBlock>
          </DropdownContent>
          <ResultsSlider slug={slug} images={content.slider} />
        </div>
      </DropdownInside>
    </DropdownItem>
  );
};

const DropdownItem = styled.li`
  background-color: ${COLORS.background.blue};
`;

const DropdownTitle = styled.h5`
  position: relative;
  font-family: Unbounded;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: ${COLORS.font.white};

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition:
    padding-bottom 0.3s ease,
    padding-top 0.3s ease;

  padding-bottom: ${({ $isopendropdown }) => ($isopendropdown ? "12px" : "85px")};
  padding-top: ${({ $isopendropdown }) => ($isopendropdown ? "43px" : "85px")};

  svg {
    width: 71px;
    height: 71px;
    padding: 18px;
    border-radius: 50%;
    background-color: ${COLORS.background.white};
    transform: ${({ $isopendropdown }) => ($isopendropdown ? "rotate(-180deg)" : "rotate(0)")};
    transition: transform 0.3s ease;
  }

  @media ${BREAKPOINTS.laptop} {
    font-size: 2.29vw;
    padding-bottom: ${({ $isopendropdown }) => ($isopendropdown ? "0.86vw" : "6.07vw")};
    padding-top: ${({ $isopendropdown }) => ($isopendropdown ? "3.07vw" : "6.07vw")};
    svg {
      width: 5.07vw;
      height: 5.07vw;
      padding: 1.29vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const MobileDropdownTitle = styled.h5`
  display: none;

  @media ${BREAKPOINTS.mobile} {
    position: relative;
    font-family: Unbounded;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: ${COLORS.font.white};

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;

    transition:
      padding-bottom 0.3s ease,
      padding-top 0.3s ease;

    word-break: break-word;
    font-size: 7.5vw;
    padding-bottom: ${({ $isopendropdown }) => ($isopendropdown ? "42.81vw" : "42.81vw")};
    padding-top: ${({ $isopendropdown }) => ($isopendropdown ? "9.38vw" : "9.38vw")};
    & > svg {
      position: absolute;
      bottom: ${({ $isopendropdown }) => ($isopendropdown ? "6.25vw" : "6.25vw")};
      right: 0;
      width: 17.81vw;
      height: 17.81vw;
      padding: 3.44vw;

      border-radius: 50%;
      background-color: ${COLORS.background.white};
      transform: ${({ $isopendropdown }) => ($isopendropdown ? "rotate(-180deg)" : "rotate(0)")};
      transition: transform 0.3s ease;
    }
  }
`;

const DropdownContent = styled(ContentWr)``;

const DropdownInside = styled.div`
  overflow: hidden;
  width: 100%;
  transform: ${({ $isopendropdown }) => ($isopendropdown ? "scale(1)" : "scale(0.8)")};
  transform-origin: top center;
  background-color: ${COLORS.blue};

  transition:
    height 0.3s ease,
    border-radius 0.3s ease,
    transform 0.3s ease;

  height: ${({ $isopendropdown, $contentheight }) => ($isopendropdown && $contentheight ? `${$contentheight}px` : "0")};
  will-change: height, border-radius;
`;

const AddressBlock = styled.p`
  font-family: "Wix Madefor Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${COLORS.font.white};

  display: flex;
  align-items: center;
  gap: 18px;

  margin-bottom: 48px;

  @media ${BREAKPOINTS.laptop} {
    font-size: 1.43vw;
    gap: 1.29vw;
    margin-bottom: 3.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: none;
  }
`;

const MobileAddressBlock = styled.div`
  display: none;
  font-family: "Wix Madefor Text";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  color: ${COLORS.font.white};

  display: flex;
  align-items: center;

  @media ${BREAKPOINTS.mobile} {
    display: flex;
    position: absolute;
    opacity: ${({ $isopendropdown }) => ($isopendropdown ? "1" : "0")};
    transform: ${({ $isopendropdown }) => ($isopendropdown ? "translateX(0)" : "translateX(-20vw)")};
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
    left: 0;
    bottom: 24vw;
    font-size: 5vw;
    gap: 4.2vw;
  }
`;

const CardsBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  margin-bottom: 30px;
  @media ${BREAKPOINTS.laptop} {
    gap: 2.14vw;
    margin-bottom: 2.14vw;
  }
  @media ${BREAKPOINTS.mobile} {
    flex-direction: column;
    gap: 5vw;
    margin-bottom: 5vw;
  }
`;

export default ResultsDropdown;
