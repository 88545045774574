import React from 'react';

import styled from 'styled-components';

import { ContentWr, H2 } from '../../../styles/textTags';
import { ARTISTS_DATA } from '../../../features/data';
import { BREAKPOINTS, COLORS } from '../../../styles/variables';

import 'aos/dist/aos.css';
import ArtistCard from './ArtistCard';

import img from '../../../assets/artist/1.png';

const Artists = () => {
  return (
    <Section id='artists'>
      <ArtistsContentWr>
        {/*<SvgSelector svg="artistsBg" />*/}
        <H2 data-aos='zoom-in'>Художники проекта</H2>
        <ArtistCardsBlock>
          {ARTISTS_DATA.map((card, index) => (
            <ArtistCard key={index} title={card.title} paragraph={card.desc} />
          ))}
        </ArtistCardsBlock>
      </ArtistsContentWr>
    </Section>
  );
};

const Section = styled.section`
  padding: 100px 0;
  background-color: ${COLORS.background.blurRed};
  position: relative;
  &:after {
    pointer-events: none;
    content: '';
    display: block;
    position: absolute;
    bottom: 5%;
    right: -5%;
    width: 650px;
    height: 1300px;
    background-image: url(${img});
    background-size: cover;
    z-index: 0;
  }
  @media ${BREAKPOINTS.laptop} {
    padding: 7.14vw 0;
    &:after {
      width: 46.43vw;
      height: 92.86vw;
    }
  }
  @media ${BREAKPOINTS.mobile} {
    padding: 12.5vw 0;
    &:after {
      width: 110vw;
      height: 120vw;
      bottom: -1%;
      right: 0;
    }
  }
`;

const ArtistsContentWr = styled(ContentWr)`
  position: relative;

  svg {
    position: absolute;
    right: -550px;
    top: -260px;
    width: 1070px;
    height: 1330px;
  }

  @media ${BREAKPOINTS.laptop} {
    svg {
      width: 76.43vw;
      height: 95vw;
      right: -39.29vw;
      top: -18.57vw;
    }
  }

  @media ${BREAKPOINTS.mobile} {
    svg {
      width: 152.19vw;
      height: 159.06vw;
      right: -37vw;
      bottom: -80vw;
      top: auto;
    }
  }
`;

const ArtistCardsBlock = styled.div`
  margin-top: 61px;
  display: grid;

  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-auto-flow: row;

  gap: 20px;

  & > *:nth-child(7) {
    grid-column: 2;
  }

  @media ${BREAKPOINTS.laptop} {
    margin-top: 4.36vw;
    gap: 1.43vw;
  }
  @media ${BREAKPOINTS.mobile} {
    display: flex;
    flex-direction: column;
    margin-top: 7.5vw;
    gap: 3.75vw;
  }
`;

export default Artists;
